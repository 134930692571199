<template>
  <div class="col-md-4 ml-auto mr-auto">
    <card class="card-lock text-center" no-footer-line>
      <img slot="header" src="hu-96.png" alt="..." />
      <h4 class="card-title">Enter token</h4>
      <span
        >If you don't have any token, please contact ASA office@asa.az
      </span>
      <fg-input
        v-model="token"
        type="text"
        placeholder="Enter token.."
        v-validate="model.token"
        data-vv-validate-on="change"
        name="token"
        :error="getError('token')"
      ></fg-input>
      <n-button slot="footer" type="primary" @click.native="onUnlock" wide
        >Unlock</n-button
      >
      <span v-if="notfound">Token is not valid</span>
    </card>
  </div>
</template>
<script>
import { GetUser } from "../../../services/user";
import { GenerateToken } from "../../../services/crypto";
import { SetUser, SetCookie } from "../../../services/localstate";
export default {
  props: ["id"],
  data() {
    return {
      token: "",
      notfound: false,
      model: {
        token: {
          required: true,
        },
      },
    };
  },
  methods: {
    onUnlock() {
      this.notfound = false;
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          GetUser(this.token).then((response) => {
            if (response.data.result !== undefined) {
              SetCookie("ctoken", GenerateToken());
              SetUser(response.data.result);
              this.$router.push({ name: "Home" }).catch((e) => {});
            } else {
              this.notfound = true;
            }
          });
        }
      });
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
  },
  mounted() {
    this.token = this.id;
    if (this.id !== undefined) this.onUnlock();
  },
};
</script>
<style>
</style>
